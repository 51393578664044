// https://github.com/vitejs/vite/issues/2778#issuecomment-810086159
import { createApp } from 'vue';

import './define.global.ts';
import './styles/global.scss';

import * as Sentry from '@sentry/vue';
import { NotificationHubPlugin } from '@smartmed/notification-hub';
import {
  defaultSmedInstallOptions,
  SmedInstallPlugin,
} from '@smartmed/ui/plugins';
import {
  AutoFocusDirective,
  IntersectionDirective,
} from '@smartmed/utility/directives';
import { noop } from '@smartmed/utility/functions';
import { createPinia } from 'pinia';

import { AnalyticsPlugin } from '@/libs/Analytics/plugins/Analytics.plugin';
import { ChatWidgetPlugin } from '@/libs/ChatScript/plugins/ChatScript.plugin';
import router from '@/router';
import { mediaOptions } from '@/shared/BrowserApi/Media/media.options';
import { MediaValue } from '@/shared/BrowserApi/Media/mediaValue.enum';
import { ThemePlugin } from '@/shared/theme/plugin';

import App from './App.vue';

if (!window.IntersectionObserver) {
  import('intersection-observer/intersection-observer').then(noop).catch(noop);
}

const app = createApp(App);

if (
  GLOBAL_CONFIG.ENVIRONMENT !== 'development' &&
  GLOBAL_CONFIG.ENVIRONMENT !== 'cypress'
) {
  Sentry.init({
    app,
    dsn: GLOBAL_CONFIG.SENTRY_DSN,
    release: GLOBAL_CONFIG.SENTRY_RELEASE,
    environment: GLOBAL_CONFIG.ENVIRONMENT,
    attachProps: true,
    trackComponents: true,
    logErrors: true,
    attachStacktrace: true,
    integrations: [
      Sentry.browserTracingIntegration({ router }),
      Sentry.replayIntegration(),
    ],
    tracesSampleRate: 1.0,
    tracePropagationTargets: [
      'localhost',
      'predprod-kong-dev.smartmed.pro',
      'predprod-dev.smartmed.pro',
      'smartmed.pro',
      /^\//,
    ],
    /**
     * FIXME: in case of fine tuning
     * replaysSessionSampleRate: 0.1, // Capture replay for 10% of sessions
     * replaysOnErrorSampleRate: 1.0, // Capture 100% of sessions with an error
     * */
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}

const pinia = createPinia();

app
  .use(router)
  .use(pinia)
  .use(ThemePlugin)
  .use(SmedInstallPlugin, {
    ...defaultSmedInstallOptions,
    media: {
      popupMobileBreakpoint: mediaOptions[MediaValue.SM],
      breakpoints: mediaOptions,
    },
  })
  .use(NotificationHubPlugin, {
    disabled: GLOBAL_CONFIG.ENVIRONMENT === 'cypress',
  })
  .use(AnalyticsPlugin, {
    enabled:
      GLOBAL_CONFIG.ENVIRONMENT === 'production' ||
      GLOBAL_CONFIG.ENVIRONMENT === 'release' ||
      GLOBAL_CONFIG.ENVIRONMENT === 'staging',
  })
  .use(ChatWidgetPlugin, { enabled: GLOBAL_CONFIG.ENVIRONMENT !== 'cypress' })
  .directive('auto-focus', AutoFocusDirective)
  .directive('intersection', IntersectionDirective)
  .mount('#app');
